"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 512 67", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "xMidYMid" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M221.034 43.303h33.832l-17.889-28.781-32.833 52.037h-14.942l39.935-62.508c1.736-2.525 4.63-4.051 7.84-4.051 3.104 0 5.998 1.473 7.682 3.946l40.093 62.613H269.81l-7.05-11.628h-34.253l-7.472-11.628zM376.251 54.93V.631h-12.68v59.614c0 1.631.631 3.21 1.841 4.42s2.841 1.894 4.63 1.894h57.825l7.472-11.628H376.25zm-209.78-9.734c12.313 0 22.31-9.944 22.31-22.256 0-12.313-9.997-22.31-22.31-22.31h-55.473V66.56h12.676v-54.3h41.956c5.893 0 10.628 4.789 10.628 10.682 0 5.892-4.735 10.68-10.628 10.68l-35.747-.052 37.851 32.99h18.416l-25.466-21.362h5.788zM32.97 66.559C14.77 66.56 0 51.827 0 33.622 0 15.416 14.77.632 32.97.632h38.32c18.204 0 32.963 14.784 32.963 32.99 0 18.205-14.759 32.937-32.964 32.937H32.97zm37.468-11.628c11.791 0 21.341-9.524 21.341-21.31 0-11.785-9.55-21.361-21.341-21.361h-36.62c-11.787 0-21.342 9.576-21.342 21.362 0 11.785 9.555 21.309 21.341 21.309h36.62zm240.78 11.628c-18.204 0-32.99-14.732-32.99-32.937 0-18.206 14.786-32.99 32.99-32.99h45.514l-7.42 11.628H312.06c-11.786 0-21.362 9.576-21.362 21.362 0 11.785 9.576 21.309 21.362 21.309h45.723l-7.472 11.628h-39.093zm155.06-11.628c-9.734 0-17.995-6.524-20.52-15.522h54.194l7.471-11.628h-61.665c2.525-8.945 10.786-15.521 20.52-15.521h37.2L511 .63h-45.565c-18.205 0-32.99 14.785-32.99 32.99 0 18.206 14.785 32.938 32.99 32.938h39.094L512 54.931h-45.723z", fill: "#EA1B22" }) })));
}
exports.default = Svg;
